<template>
<div v-if="megasena && megasena.megasena">
    <div class="d-flex gap-5 main-draw">
        <div class="results">
            <h2>
                Números<br />
                Sorteados
            </h2>
            <span>Sorteio nº {{ megasena.megasena.concurso }}</span>
            <ul class="numbers">
                <li v-for="number in megasena.megasena.dezenasSorteadas" :key="number">{{ number }}</li>
            </ul>
        </div>
        <div class="d-flex draw-info-wrapper">
            <div class="draw">
                <div class="draw-info">
                    <p class="d-flex gap-3 align-items-center">
                        <span>Sorteio <strong>{{ megasena.megasena.dataSorteio }}</strong></span>
                        <span>Concurso <strong>{{ megasena.megasena.concurso }}</strong></span>
                    </p>
                    <p>
                        Local de Sorteio:<br />
                        <strong>{{ megasena.megasena.localDoSorteio }}</strong>
                    </p>
                    <div class="draw-value">
                        <span>Valor do prêmio:</span>
                        <h3>{{ formatarValor(megasena.megasena.valorSorteado) }}</h3>
                    </div>
                </div>
                <span class="divisor"></span>
                <table>
                    <tr align="center">
                        <td><span class="t-title">Premiação</span></td>
                        <td><span class="t-title">Ganhadores</span></td>
                        <td><span class="t-title">Prêmio</span></td>
                    </tr>
                    <tr align="center" v-for="premiacao in megasena.megasena.premiacoes" :key="premiacao.faixa">
                        <td>{{ premiacao.categoria }}</td>
                        <td>{{ premiacao.ganhadores }}</td>
                        <td>{{ formatarValor(premiacao.premio) }}</td>
                    </tr>
                </table>
            </div>
            <div class="next-draw">
                <h3>
                    Próximo<br />
                    Concurso
                </h3>
                <p class="next-draw-value">{{ formatarValorMilhoes(megasena.megasena.valorEstimadoProxConcurso) }}</p>
                <span class="acumulate" v-if="megasena.megasena.acumuladaProxSorteio">Acumulada!</span>
                <span class="draw-span">Sorteio {{ megasena.megasena.dataProxSorteio }}</span>
                <span class="draw-number">Concurso {{ megasena.megasena.proxConcurso }}</span>
                <a href="https://investloto.com.br" class="join-secondary">Participe</a>
            </div>
        </div>
    </div>
    <div class="ms-alert">
        <span class="target"></span>
        <div class="inner">
            <p v-if="megasena.megasena.acumuladaProxSorteio">
                A Mega-Sena acumulou e a estimativa do prêmio para o próximo concurso ({{ megasena.megasena.proxConcurso }}), que será sorteado no dia {{ megasena.megasena.dataProxSorteio }}, é de {{ formatarValorMilhoes(megasena.megasena.valorEstimadoProxConcurso) }}. <br />
                Aumente consideravelmente sua chance de ser o novo ganhador do prêmio milionário da Mega.
            </p>
            <p v-else>
                A estimativa para o prêmio da Mega (concurso {{ megasena.megasena.proxConcurso }}), que será sorteado no dia {{ megasena.megasena.dataProxSorteio }}, é de {{ formatarValorMilhoes(megasena.megasena.valorEstimadoProxConcurso) }}. <br />
                Aumente consideravelmente sua chance de ser o novo ganhador do prêmio milionário da Mega.
            </p>
            <a href="https://investloto.com.br" class="join-default">Participe</a>
        </div>
    </div>
</div>
<div v-else>
    <p class="text-center">
        <img src="@/assets/loadin_gif.gif" alt="LOADING..." style="width: auto; height: 200px;">
    </p>
</div>

<!--  -->
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            megasena: {},
        }
    },
    async mounted() {
        try {
            await this.getResult();
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        async getResult() {
            try {
                const response = await axios.get('https://resultados-loterias-json.vercel.app/api/resultados');
                this.megasena = response.data;
            } catch (error) {
                console.error(error);
                throw error; // Propaga o erro para ser capturado no bloco "catch" acima
            }
        },
        formatarValor(valor) {
            const valorInteiro = parseInt(valor);
            if (isNaN(valorInteiro)) {
                return valor;
            }

            if (valorInteiro === 1000000) {
                return 'R$ 1 Milhão';
            }

            const valorFormatado = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(valorInteiro);

            return valorFormatado;
        },
        formatarValorMilhoes(valor) {
            const valorInteiro = parseInt(valor);
            if (isNaN(valorInteiro)) {
                return valor;
            }

            if (valorInteiro === 1000000) {
                return 'R$ 1 Milhão';
            }

            const valorFormatado = `R$ ${Math.floor(valorInteiro / 1000000)} Milhões`;
            return valorFormatado;
        },
    },
}
</script>

<style scoped>
.results {
    width: 100%;
    max-width: 326px;
    height: 362px;
    border-radius: 20px;
    background: linear-gradient(174deg, #42C669 13.31%, #3E9F88 94.97%);
    box-shadow: 3px 4px 20px 0px rgba(63, 184, 167, 0.30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.results h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.results span {
    color: #FFF;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 10px 0 30px 0;
}

.results ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0;
    padding: 0;
    max-width: 200px;
}

.results ul li {
    display: flex;
    width: 50px;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: linear-gradient(180deg, #FFF 0%, #A8C3B4 100%);
    box-shadow: 4px 4px 15px 0px rgba(63, 197, 168, 0.40);
    color: #234028;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.draw-info-wrapper {
    width: 100%;
    height: 362px;
    border-radius: 20px;
    border: 1px solid #EAEAEA;
    box-shadow: 3px 4px 20px 0px rgba(63, 184, 167, 0.30);
    align-items: center;
    justify-content: space-between;
}

.draw-info {
    padding: 0 3rem;
}

.draw {
    width: 100%;
}

span.divisor {
    width: 100%;
    height: 1px;
    display: table;
    background: #EAEAEA;
    margin: 15px 0;
}

.draw-value h3 {
    color: #FFCC18;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
}

table {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
}

span.t-title {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.next-draw {
    width: 100%;
    max-width: 326px;
    height: 362px;
    flex-shrink: 0;
    border-radius: 0px 20px 20px 0px;
    background: linear-gradient(174deg, #42C669 13.31%, #3E9F88 94.97%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.next-draw h3 {
    color: #FFF;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.next-draw-value {
    color: #FFEB39;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.5px;
    text-transform: uppercase;
    margin-top: 15px;
}

.acumulate {
    color: #FFF;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.draw-span,
.draw-number {
    color: #FFF;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 17px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.draw-number {
    margin-top: 5px;
}

.join-secondary {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #1D5071;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 17px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    transition: .3s ease all;
}

.join-secondary:hover {
    background: #0b2333;
}

@media (max-width: 1200px) {
    .results {
        max-width: 100% !important;
    }

    .main-draw {
        flex-direction: column;
    }

    .draw-info-wrapper {
        height: auto !important;
    }

    .draw-info-wrapper p {
        margin: 0;
    }
}

@media (max-width: 900px) {
    .draw-info-wrapper {
        flex-direction: column;
    }

    .draw-info-wrapper p {
        margin-top: 2rem;
    }

    .next-draw {
        border-radius: 0 0 20px 20px;
        width: 100%;
        max-width: 100% !important;
        margin-top: 2rem;
    }
}

.ms-alert {
    display: flex;
    width: 100%;
    padding: 1rem 2rem;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    border-radius: 20px;
    border: 1px solid #EAEAEA;
    margin-top: 2.5rem;
}

.target {
    width: 3px;
    height: 89px;
    display: table;
    background: #285D7E;
}

.inner p {
    margin: 0;
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.join-default {
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #285D7E;
    color: #285D7E;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    transition: .3s ease all;
}

.join-default:hover {
    background: #285D7E;
    color: #FFF;
}

@media (max-width: 900px) {
    .target {
        display: none;
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inner p {
        text-align: center;
    }

    .join-default {
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        border: 1px solid #285D7E;
        color: #285D7E;
        font-family: 'Futura Md BT', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 10px auto;
    }
}
</style>
