<template>
<div class="horizontal-menu mt-4 container">
    <div class="scroller" ref="menuScroller">
        <router-link to="/" id="linkMegaSena" :class="{ 'active': isLinkActive('/') }">Mega-Sena</router-link>
        <router-link to="/duplasena" id="linkDuplaSena" :class="{ 'active': isLinkActive('/duplasena') }">Dupla Sena</router-link>
        <router-link to="/lotofacil" id="linkLotofacil" :class="{ 'active': isLinkActive('/lotofacil') }">Lotofácil</router-link>
        <router-link to="/quina" id="linkQuina" :class="{ 'active': isLinkActive('/quina') }">Quina</router-link>
        <router-link to="/timemania" id="linkTimemania" :class="{ 'active': isLinkActive('/timemania') }">Timemania</router-link>
        <router-link to="/lotomania" id="linkLotomania" :class="{ 'active': isLinkActive('/lotomania') }">Lotomania</router-link>
        <router-link to="/dia-de-sorte" id="linkDiaDeSorte" :class="{ 'active': isLinkActive('/dia-de-sorte') }">Dia de Sorte</router-link>
        <router-link to="/super-sete" id="linkSuperSete" :class="{ 'active': isLinkActive('/super-sete') }">Super Sete</router-link>
        <router-link to="/mais-milionaria" id="linkMaisMilionaria" :class="{ 'active': isLinkActive('/mais-milionaria') }">+ Milionária</router-link>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        isLinkActive(linkPath) {
            return this.$route.path === linkPath;
        }
    }
};
</script>

<style scoped>
.horizontal-menu {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 15px;
}

.scroller {
    display: inline-flex;
    gap: 40px;
    padding: 0 20px 10px 20px;
    flex-wrap: nowrap;
}

@media (min-width: 1024px) {
    .scroller {
        justify-content: center !important;
        width: 100%;
    }
}

.scroller a {
    display: block;
    color: #245475 !important;
    text-decoration: none;
    transition: .3s ease all;
    font-family: 'Lato', sans-serif;
    
    font-size: 1.2rem;
}

.scroller a:hover {
    color: #0a2030 !important;
}

.horizontal-menu::-webkit-scrollbar {
    height: 2px;
}

@media (min-width: 600px) {
    .horizontal-menu::-webkit-scrollbar {
        height: 0px !important;
    }
}

.horizontal-menu::-webkit-scrollbar-thumb {
    background-color: #235273;
    border-radius: 6px;
}

.horizontal-menu::-webkit-scrollbar-track {
    background-color: #f8f9fa;
}

.horizontal-menu {
    scrollbar-width: thin;
    position: relative;
    z-index: 1;
}

.horizontal-menu::-moz-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 6px;
}

.horizontal-menu::-moz-scrollbar-track {
    background-color: #f8f9fa;
}

a.active {
    color: 0a2030 !important;
    font-weight: bold;
    position: relative;
    z-index: 1;
}

.active:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    display: table;
    border-radius: 50px;
    background: #75D170;
    left: 50%;
    top: 2rem;
    transform: translateX(-50%);
    z-index: 1 !important;
}
</style>
