<template>
<span class="footer-divisor"></span>
<p class="copyright">Copyright © 2023 - Investloto LTDA</p>
</template>

<style scoped>
.footer-divisor {
    width: 100px;
    height: 1px;
    background: #EAEAEA;
    display: table;
    margin: 50px auto;
}

.copyright {
    text-align: center;
    color: #909090;
    text-align: center;
    font-family: 'Futura Md BT', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto;
    display: table;
    margin-bottom: 50px;
}
</style>
