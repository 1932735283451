import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(App)

import TitleComponent from '@/components/TitleComponent'

app.use(router);
app.component("TitleComponent", TitleComponent);

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const pageTitle = to.meta.title || "Resultados da Mega Sena";
  document.title = `Resultados da Mega Sena | ${pageTitle}`;

  next();
});

app.mount("#app");
